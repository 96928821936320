import request from "@/utils/http/requset";

function getRefundErrorOrderList(data) {
  return request({
    url: "/manage/recharge/searchErrorOrder",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function callbackOrder(data) {
  return request({
    url: "/manage/recharge/callback",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  getRefundErrorOrderList,
  callbackOrder
};
